import type { RouteLocationNormalizedGeneric } from 'vue-router';
import { SponsorType, type RegisterStep } from '~/composables/useRegisterSteps';

export default defineNuxtRouteMiddleware(
   async (
      to: RouteLocationNormalizedGeneric,
      from: RouteLocationNormalizedGeneric
   ) => {
      const { anonymousId } = useAnonymousClientStorage();
      const { getAnonymousData, createAnonymousId } = useClientAuthStore();
      const { isClientAuthenticated } = useAuthClientStorage();
      const { getClientInfo, getSelectedTherapistInfo } = useClientInfo();
      const { anonymousData } = storeToRefs(useClientAuthStore());
      const { clientProfile, status } = storeToRefs(useClientInfo());
      const { currentRegisterStep } = useRegisterSteps();
      if (anonymousId.value || isClientAuthenticated.value) {
         if (isClientAuthenticated.value && !clientProfile.value) {
            await Promise.all([getClientInfo(), getSelectedTherapistInfo()]);
         } else if (!isClientAuthenticated.value && !anonymousData.value) {
            await getAnonymousData();
         }
         const isInsurance =
            clientProfile.value?.sponsor == SponsorType.Insurance ||
            anonymousData.value?.sponsor == SponsorType.Insurance;
         if (
            isInsurance &&
            status.value?.statusCode == 0 &&
            status.value?.route?.to?.name != to.name
         ) {
            const currentStepIndex = currentRegisterStep?.value?.findIndex(
               (step: RegisterStep) => step.to.name === to.name
            );
            const targetStepIndex = currentRegisterStep?.value?.findIndex(
               (step: RegisterStep) =>
                  step.to.name === status.value?.route?.to?.name
            );

            if (currentStepIndex < targetStepIndex) {
               return;
            } else {
               if (
                  status.value?.route?.to?.name ==
                  'auth-client-register-confirmedInsuranceBenefitCode'
               ) {
                  return;
               }
               return navigateTo(status.value?.route?.to);
            }
         }
      } else {
         if (to.name == 'auth-client-register-coverage') {
            return await createAnonymousId();
         } else {
            return navigateTo({ name: 'auth-client-login' });
         }
      }
      const sponsorType =
         clientProfile.value?.sponsor || anonymousData.value?.sponsor;
      const { verifyCompanyBenefitCode, getCoverageDetail } =
         useClientAuthStore();
      const { coverageDetailBody } = storeToRefs(useClientAuthStore());
      const { getTherapyTypes } = useSharedContentStore();
      const { therapyTypes } = storeToRefs(useSharedContentStore());
      const { updateAnonymousData, getNextStep } = useClientAuthStore();
      const currentStepIndex = currentRegisterStep?.value?.findIndex(
         (step: RegisterStep) => step.to.name === from.name
      );
      const targetStepIndex = currentRegisterStep?.value?.findIndex(
         (step: RegisterStep) => step.to.name === to.name
      );
      if (to.name == 'auth-client-register-confirmedCompanyBenefit') {
         if (isClientAuthenticated.value && !coverageDetailBody.value) {
            await getCoverageDetail();
         }
         await verifyCompanyBenefitCode({
            benefit_code:
               coverageDetailBody.value?.coverage?.benefit_code ||
               anonymousData.value?.benefit_code ||
               '',
            email:
               clientProfile.value?.email || anonymousData.value?.email || '',
         });
      }
      if (to.name == 'auth-client-register-therapy-type') {
         if (sponsorType == SponsorType.Company) {
            if (isClientAuthenticated.value && !coverageDetailBody.value) {
               await getCoverageDetail();
            }
            await verifyCompanyBenefitCode({
               benefit_code:
                  coverageDetailBody.value?.coverage?.benefit_code ||
                  anonymousData.value?.benefit_code ||
                  '',
               email:
                  clientProfile.value?.email ||
                  anonymousData.value?.email ||
                  '',
            });
         } else if (sponsorType == SponsorType.Insurance) {
            await Promise.all([getTherapyTypes(), getCoverageDetail()]);
         } else {
            await getTherapyTypes();
         }
         if (therapyTypes.value?.length == 1) {
            await updateAnonymousData({
               therapy_type_id: therapyTypes.value[0]?.id,
            });
            if (currentStepIndex < targetStepIndex && currentStepIndex != -1) {
               const toRoute = getNextStep(true)?.to;
               if (toRoute) return navigateTo(toRoute);
            }
         }
      }
      return;
   }
);
